(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/auth/assets/javascripts/roles.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/auth/assets/javascripts/roles.js');


'use strict';

const PERMISSIONS = {
  MODERATE_CHAT: {
    bit: 1,
    description: 'Kommentarsfält'
  },
  CREATE_GAME_CONTAINER: {
    bit: 2,
    description: 'Spelmallar'
  },
  CREATE_GAME: {
    bit: 4,
    description: 'Omgångar'
  },
  MANAGE_USERS: {
    bit: 8,
    description: 'Användare'
  },
  EDIT_RETAIL: {
    bit: 16,
    description: 'Ombudsinfo'
  },
  RETAILER_PUBLISH_UNPUBLISH: {
    bit: 32,
    description: 'Publicering och Avpublicering'
  },
  SORT_GAME: {
    bit: 64,
    description: 'Sortera spel'
  },
  NOTIFY_TEAM: {
    bit: 128,
    description: 'Skicka meddelande till följare av lag'
  },
  UNPUBLISH_CHAT: {
    bit: 256,
    description: 'Avpublicering av Kommentarsfält'
  },
  NOTIFY_GAME_PARTICIPANTS: {
    bit: 512,
    description: 'Skicka meddelande till deltagare i spelomgång'
  }
};

const ROLES = {
  OWNER: {
    name: 'Ansvarig',
    icon: 'vinnare-inverted',
    iconClass: 'icon-role-owner',
    assignable: true,
    order: 0,
    permissions: [PERMISSIONS.CREATE_GAME, PERMISSIONS.CREATE_GAME_CONTAINER, PERMISSIONS.MODERATE_CHAT, PERMISSIONS.MANAGE_USERS, PERMISSIONS.EDIT_RETAIL, PERMISSIONS.SORT_GAME, PERMISSIONS.RETAILER_PUBLISH_UNPUBLISH, PERMISSIONS.NOTIFY_TEAM, PERMISSIONS.UNPUBLISH_CHAT, PERMISSIONS.NOTIFY_GAME_PARTICIPANTS]
  },
  ADMIN: {
    name: 'Admin',
    icon: 'person',
    iconClass: 'icon-role-admin',
    assignable: true,
    order: 1,
    permissions: [PERMISSIONS.CREATE_GAME, PERMISSIONS.CREATE_GAME_CONTAINER, PERMISSIONS.MODERATE_CHAT, PERMISSIONS.MANAGE_USERS, PERMISSIONS.EDIT_RETAIL, PERMISSIONS.SORT_GAME, PERMISSIONS.NOTIFY_TEAM, PERMISSIONS.UNPUBLISH_CHAT, PERMISSIONS.NOTIFY_GAME_PARTICIPANTS]
  },
  GAME_ADMIN: {
    name: 'Speladmin',
    icon: 'person',
    iconClass: 'icon-role-game-admin',
    assignable: true,
    order: 2,
    permissions: [PERMISSIONS.CREATE_GAME, PERMISSIONS.CREATE_GAME_CONTAINER, PERMISSIONS.MODERATE_CHAT, PERMISSIONS.NOTIFY_TEAM, PERMISSIONS.NOTIFY_GAME_PARTICIPANTS]
  },
  GAME_CREATOR: {
    name: 'Spelläggare',
    icon: 'user',
    iconClass: 'icon-role-game-creator',
    assignable: true,
    order: 3,
    permissions: [PERMISSIONS.CREATE_GAME, PERMISSIONS.NOTIFY_GAME_PARTICIPANTS]
  },
  TERMINAL: {
    name: 'Terminalkonto',
    icon: 'user',
    iconClass: 'icon-role-terminal',
    assignable: true,
    order: 4,
    permissions: []
  }
};

function isAuthorized(roleName, permission) {
  let conditions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  try {
    const role = ROLES[roleName];
    return hasPermission(roleName, permission) && _checkConditional(role, permission, conditions);
  } catch (error) {
    throw new Error("isAuthorized: something went wrong: role: ".concat(roleName, ", permission: ").concat(permission));
  }
}

function hasPermission(roleName, permission) {
  try {
    const role = ROLES[roleName];
    return role.permissions.includes(permission);
  } catch (error) {
    throw new Error("hasPermission: Unknown role: ".concat(roleName));
  }
}

function _checkConditional(role, permission, conditions) {
  if (role === ROLES.GAME_CREATOR && permission === PERMISSIONS.NOTIFY_GAME_PARTICIPANTS) {
    return conditions.isGameTemplateOwner === true;
  }
  if (role === ROLES.GAME_CREATOR && permission === PERMISSIONS.CREATE_GAME) {
    return conditions.isGameTemplateOwner === true;
  }
  return true;
}

setGlobal('svs.components.marketplaceRetailAdminData.auth.roles', {
  PERMISSIONS,
  ROLES,
  hasPermission,
  isAuthorized
});

 })(window);